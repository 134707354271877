<trlp-page-header [pageTitle]="'My Events'"></trlp-page-header>
<div class="main-container">
  @if (webinerQueries.isPending()) {
    <div>loading...</div>
  } @else if (webinerQueries.isError()) {
  } @else {
    @if (webinerQueries.data(); as webiners) {
      @for (webiner of webiners; track webiner) {
        <trlp-webiner-card [WebinerDetails]="webiner"></trlp-webiner-card>
      }
    }
  }
</div>
