import { Component } from '@angular/core';
import { FeatureWebinerDetailsComponent } from './feature-webiner-details/feature-webiner-details.component';

@Component({
  selector: 'trueleap-plus-public-webiner-details',
  standalone: true,
  imports: [FeatureWebinerDetailsComponent],
  template: `
    <div class="main-container">
      <trueleap-plus-feature-webiner-details></trueleap-plus-feature-webiner-details>
    </div>
  `,
  styles: [
    `
      .main-container {
      }
    `,
  ],
})
export class PublicWebinerDetailsComponent {}
