import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  CommonModule,
  JsonPipe,
  NgFor,
  NgIf,
  SlicePipe,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  NgZone,
  computed,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatButtonToggleChange,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';
import {
  injectQuery,
  injectQueryClient,
} from '@tanstack/angular-query-experimental';
import { WebinerCardComponent } from '@trueleap/ui-kit';
import { derivedAsync } from 'ngxtension/derived-async';
import { lastValueFrom, map } from 'rxjs';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { WebinerFilterComponent } from './webiner-filter/webiner-filter.component';
import { WebinerService } from './webiner.service';
import { MatSelectModule } from '@angular/material/select';
import { PublicWebinerFilterComponent } from './public-webiner-filter/public-webiner-filter.component';
import { formatDate } from 'date-fns';

export interface Author {
  id: string;
  name: string;
  firstname: string;
  lastname: string;
}
type DateFilter = {
  startDate?: string | null;
  endDate?: string | null;
};
@Component({
  selector: 'trueleap-plus-public-webiner',
  standalone: true,
  imports: [
    NgSelectModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    NgFor,
    SlicePipe,
    NgIf,
    MatChipsModule,
    WebinerCardComponent,
    RouterModule,
    JsonPipe,
    MatCardModule,
    CommonModule,
    WebinerFilterComponent,
    MatButtonToggleModule,
    MatSidenavModule,
    EventCalendarComponent,
    MatSelectModule,
    PublicWebinerFilterComponent,
  ],
  template: `
    <div class="main-container">
      <div class="filter-container">
        <trueleap-plus-public-webiner-filter
          (applyFilter)="
            setFilters($event)
          "></trueleap-plus-public-webiner-filter>
      </div>
      <mat-card class="data-container">
        <div
          class="sort-container"
          [ngClass]="dataView() !== 'CALENDAR' ? 'item-between' : 'item-end'">
          @if (dataView() !== 'CALENDAR') {
            <div class="sort-button-container">
              <span> Sort By </span>
              @for (option of sortOption; track option) {
                <div
                  class="sort-button"
                  mat-button
                  (click)="onSortChange(option.value)"
                  [ngClass]="{ 'sort-active': sortSignal() === option.value }">
                  {{ option.viewValue }}
                </div>
              }
            </div>
          }
          <div class="view-container">
            @for (mode of ViewMode; track mode) {
              <mat-icon
                class="view-button"
                [ngClass]="{ active: dataView() === mode.value }"
                (click)="changeDataView(mode.value)">
                {{ mode.icon }}
              </mat-icon>
            }
          </div>
        </div>
        @if (dataView() === 'CALENDAR') {
          <div class="calendar-container">
            <trueleap-plus-event-calendar></trueleap-plus-event-calendar>
          </div>
        } @else {
          <div
            [ngClass]="
              dataView() === 'LIST'
                ? 'course-container-list'
                : 'course-container-grid'
            ">
            @if (webiners.isPending()) {
              <div>loading...</div>
            } @else if (webiners.isError()) {
            } @else {
              @if (webiners.data(); as courses) {
                <trlp-webiner-card
                  [viewMode]="dataView() === 'LIST' ? 'LIST' : 'GRID'"
                  *ngFor="let webiner of webiners.data(); let i = index"
                  [WebinerDetails]="webiner">
                </trlp-webiner-card>
              }
            }
          </div>
        }
      </mat-card>
    </div>
  `,
  styles: [
    `
      @import 'responsive';
      .main-container {
        display: flex;
        margin: 0 auto;
        gap: 1rem;
        flex-direction: column;
        padding: 0.2rem;

        @include lg-screen {
          flex-direction: row;
          padding: 1rem;
        }

        .filter-container {
          margin-top: 1rem;
          margin-bottom: 1rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: center;
          padding: 1rem;
          width: 18rem;
          @include lg-screen {
            width: 26rem;
            padding: 0rem 1rem 0rem 0;
            justify-content: flex-start;
            min-height: 50vh;
          }

          h4 {
            margin: 0;
            text-align: left;
          }

          .filter-item {
            width: 100%;
          }

          .filter-result {
            display: flex;
            flex-direction: column;
            p {
              margin: 0;
            }
          }
        }

        .data-container {
          margin-top: 1rem;
          margin-bottom: 1rem;
          min-height: calc(100vh - 7rem);
          width: 100%;
        }
        .sort-container {
          padding: 1.5rem 1rem;
          display: flex;
          align-items: center;
          .sort-button-container {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            .sort-button {
              padding-bottom: 0.5rem;
              cursor: pointer;
            }
            .sort-active {
              border-bottom: 1px #4caf50 solid;
              color: #4caf50;
            }
          }
          .view-container {
            display: flex;
            align-items: center;
            gap: 1rem;
            .view-button {
              cursor: pointer;
            }
            .active {
              color: #ff914d;
            }
          }
        }
        .course-container-grid {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 0.75rem;
          padding: 0 1rem 2rem;
          @include sm-screen {
            grid-template-columns: repeat(2, 1fr);
          }
          @include md-screen {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        .course-container-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 0 1rem 2rem;
        }
      }
      .item-between {
        justify-content: space-between;
      }
      .item-end {
        justify-content: flex-end;
      }
      .calendar-container {
        padding: 1.5rem 1rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicWebinerComponent {
  webinerService = inject(WebinerService);
  sortSignal = signal<string>('POPULARITY');
  filterSignal = signal<string>('');
  ngZone = inject(NgZone);
  queryClient = injectQueryClient();
  authorFilterSignal = signal<string[]>([]);
  tagsFilterSignal = signal<string[]>([]);
  dateFilterSignal = signal<DateFilter | null>(null);
  sortOption = [
    { value: 'POPULARITY', viewValue: 'Popularity' },
    { value: 'PRICE-ASC', viewValue: 'Price- Low to High' },
    { value: 'PRICE-DESC', viewValue: 'Price- High to Low' },
    { value: 'AGE', viewValue: 'Newest First' },
  ];
  ViewMode = [
    {
      value: 'GRID',
      icon: 'grid_view',
    },
    {
      value: 'LIST',
      icon: 'format_list_bulleted',
    },
    {
      value: 'CALENDAR',
      icon: 'calendar_month',
    },
  ];
  webiners = injectQuery(() => ({
    queryKey: ['market-events'],
    queryFn: () =>
      lastValueFrom(
        this.webinerService.fetchMarketplaceEvents(
          this.sortSignal(),
          this.filterSignal()
        )
      ),
  }));
  selectedAuthor = signal<Author[]>([]);
  dataView = signal<string>('GRID');
  selectAuthor(event: Author[]) {
    this.selectedAuthor.set(event);
  }

  clearAuthors() {
    this.selectedAuthor.set([]);
  }
  changeDataView(mode: string) {
    this.dataView.set(mode);
  }
  authors = computed(() => {
    return (
      this.webiners.data()?.map(webiner => {
        return {
          ...webiner.authorInfo,
          name: `${webiner.authorInfo.firstname} ${webiner.authorInfo.lastname}`,
        };
      }) || []
    );
  });

  onSortChange(sort: string) {
    this.sortSignal.set(sort);
    this.ngZone.run(() => {
      this.webiners.refetch();
    });
  }
  setFilters(filter: any) {
    const filterStringArray: string[] = [];
    if (filter?.author?.length > 0) {
      this.authorFilterSignal.set(filter?.author);
      filterStringArray.push(`author=${filter.author.join(',')}`);
    } else {
      this.authorFilterSignal.set([]);
    }
    if (filter?.categories?.length > 0) {
      this.tagsFilterSignal.set(filter?.categories);
      filterStringArray.push(`tags=${filter.categories.join(',')}`);
    } else {
      this.tagsFilterSignal.set([]);
    }
    if (filter?.startDate && filter?.endDate) {
      this.dateFilterSignal.set({
        startDate: filter?.startDate,
        endDate: filter?.endDate,
      });
      filterStringArray.push(
        `startDate=${formatDate(filter?.startDate, 'yyyy-MM-dd')}&endDate=${formatDate(filter?.endDate, 'yyyy-MM-dd')}`
      );
    } else {
      this.dateFilterSignal.set(null);
    }
    this.ngZone.run(() => {
      this.filterSignal.set(filterStringArray.join('&'));
      this.webiners.refetch();
    });
  }
  breakpointObserver = inject(BreakpointObserver);
  isMobile = derivedAsync(
    () =>
      this.breakpointObserver
        .observe(Breakpoints.XSmall)
        .pipe(map(state => state.matches)),
    {
      initialValue: false,
    }
  );
}
