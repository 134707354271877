import { CommonModule } from '@angular/common';
import { Component, NgZone, computed, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { MtxDrawer, MtxDrawerModule } from '@ng-matero/extensions/drawer';
import {
  injectMutation,
  injectQuery,
  injectQueryClient,
} from '@tanstack/angular-query-experimental';
import { AuthStore } from '@trueleap/auth';
import { PaymentComponent } from '@trueleap/payment';
import {
  ContentAction,
  ContentContainer,
  ContentInner,
  WebinerParticipant,
} from '@trueleap/ui-kit';
import { format, isAfter, isBefore } from 'date-fns';
import { RRule, rrulestr } from 'rrule';
import { lastValueFrom } from 'rxjs';
import { AddParticipantWebinerComponent } from '../add-participant/add-participant.component';
import { WebinerLiveComponent } from '../webiner-live.component';
import { WebinerParticipantsComponent } from '../webiner-participants/webiner-participants.component';
import { WebinerService } from '../webiner.service';

export type DialogRefData = {
  isCancel: boolean;
  data: any;
};

@Component({
  selector: 'trueleap-plus-feature-webiner-details',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PaymentComponent,
    MatChipsModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    WebinerLiveComponent,
    MatIconModule,
    WebinerParticipantsComponent,
    MatDialogModule,
    MtxDrawerModule,
    ContentInner,
    ContentContainer,
    ContentAction,
  ],
  templateUrl: './feature-webiner-details.component.html',
  styleUrl: './feature-webiner-details.component.scss',
})
export class FeatureWebinerDetailsComponent {
  eventId = '';
  checkoutSession = signal<boolean>(false);
  isCreator = inject(AuthStore).isCreator;
  isLiveWebiner = signal<boolean>(false);
  user = inject(AuthStore).profile;
  webinerService = inject(WebinerService);
  route = inject(ActivatedRoute);
  mtxDrawer = inject(MtxDrawer);
  ngZone = inject(NgZone);
  stripeKey = '';
  returnUrl = signal<string[]>([]);
  amount = 0;
  currency = '';
  dialog = inject(MatDialog);
  mtxDialog = inject(MtxDialog);
  router = inject(Router);
  // event day/date/frequency
  startDate: Date | null;
  endDate: Date | null;
  frequency: string;
  daysOfWeek: string[];
  queryClient = injectQueryClient();
  webinerDetailsData = injectQuery(() => ({
    queryKey: ['market-webiner-details', this.eventId],
    queryFn: () =>
      lastValueFrom(
        this.isCreator()
          ? this.webinerService.fetchEventByID(this.eventId)
          : this.webinerService.fetchMarketplaceEventsById(this.eventId)
      ),
    refetchOnWindowFocus: false,
  }));
  mutation = injectMutation(client => ({
    mutationKey: ['webiner-participants', this.eventId],
    mutationFn: (data: WebinerParticipant) =>
      lastValueFrom(
        this.webinerService.addWebinerParticipant(this.eventId, data)
      ),
    onSuccess: async () => {
      return await client.invalidateQueries({
        queryKey: ['webiner-participants', this.eventId],
      });
    },
  }));
  constructor() {
    this.route.params.subscribe(params => {
      this.eventId = params['id']; // Access the 'id' parameter from the URL
      this.returnUrl.set(['events', this.eventId]);
    });
  }
  rruledata = computed(() => {
    const data = this.webinerDetailsData.data();
    if (!data?.rrule) {
      return null;
    }

    const rruleObj = rrulestr(data.rrule);
    return {
      startDate: rruleObj.origOptions.dtstart
        ? format(new Date(rruleObj.origOptions.dtstart), 'dd-MMM-yyyy')
        : null,
      endDate: rruleObj.origOptions.until
        ? format(new Date(rruleObj.origOptions.until), 'dd-MMM-yyyy')
        : null,
      frequency: rruleObj.origOptions.freq
        ? this.getFrequency(rruleObj.origOptions.freq)
        : null,
      daysOfWeek: rruleObj.origOptions.byweekday
        ? this.extractDaysFromRrule(data.rrule)
        : [],
    };
  });
  getFrequency(freq: number): string {
    switch (freq) {
      case 0:
        return 'Yearly';
      case 1:
        return 'Monthly';
      case 2:
        return 'Weekly';
      case 3:
        return 'Daily';
      case 4:
        return 'Hourly';
      case 5:
        return 'Minutely';
      case 6:
        return 'Secondly';
      default:
        return 'Unknown';
    }
  }

  extractDaysFromRrule(rruleString: string): string[] {
    // Extract the RRULE part from the string
    const rrulePart = rruleString.split('RRULE:')[1];
    if (!rrulePart) {
      return [];
    }

    // Split the RRULE string by semicolons
    const components = rrulePart.split(';');
    const bydayComponent = components.find(component =>
      component.startsWith('BYDAY')
    );

    let days: string[] = [];
    if (bydayComponent) {
      days = bydayComponent.split('=')[1].split(',');
    }

    // Map of RRULE days to full names
    const dayMap: { [key: string]: string } = {
      MO: 'Monday',
      TU: 'Tuesday',
      WE: 'Wednesday',
      TH: 'Thursday',
      FR: 'Friday',
      SA: 'Saturday',
      SU: 'Sunday',
    };

    return days.map(day => dayMap[day]);
  }

  checkout() {
    if (this.user()) {
      this.webinerService
        .initiatePayment({
          type: 'EVENT',
          resourceId: this.eventId,
        })
        .subscribe(
          (res: any) => {
            if (!res.data.amount || res.data.amount === 0) {
              this.queryClient.invalidateQueries({
                queryKey: ['market-webiner-details', this.eventId],
              });
            } else {
              this.stripeKey = res.data.clientSecret;
              this.amount = res.data.amount;
              this.currency = res.data.currency;
              this.checkoutSession.set(true);
            }
          },
          err => {
            console.error(err);
            this.checkoutSession.set(false);
          }
        );
    } else {
      this.router.navigate(['auth'], {
        queryParams: {
          returnUrl: this.router.url,
        },
      });
    }
  }

  private addTimeStringToDate(date: Date, timeStr: string): Date {
    const timeReg = /(\d+)[.|:](\d+)\s?(\w+)/;
    const parts = timeStr.match(timeReg);
    if (!parts) return date;
    const hours = /am/i.test(parts[3])
      ? (function (am) {
          return am < 12 ? am : 0;
        })(parseInt(parts[1], 10))
      : (function (pm) {
          return pm < 12 ? pm + 12 : 12;
        })(parseInt(parts[1], 10));

    const minutes = parseInt(parts[2], 10);

    date.setHours(hours);

    date.setMinutes(minutes);
    date.setSeconds(0);

    return date;
  }

  joinLiveWebiner() {
    const webinerDetails = this.webinerDetailsData.data();
    if (!webinerDetails) return;
    const rrule = RRule.fromString(webinerDetails.rrule);
    const webinerStartTime = rrule.origOptions.dtstart
      ? this.addTimeStringToDate(
          new Date(rrule.origOptions.dtstart),
          webinerDetails.startTime
        )
      : this.addTimeStringToDate(new Date(), webinerDetails.startTime);

    const webinerEndTime = rrule.options?.until
      ? this.addTimeStringToDate(
          new Date(rrule.options.until),
          webinerDetails.endTime
        )
      : this.addTimeStringToDate(new Date(), webinerDetails.endTime);

    // console.log('webinerStartTime', webinerStartTime);
    // console.log('webinerEndTime', webinerEndTime);
    // console.log('current time', new Date());
    // console.log(webinerDetails);

    if (isBefore(new Date(), webinerStartTime)) {
      return this.mtxDialog.alert(
        'Event Not Started',
        'Event has not started yet. Please check back later.'
      );
    }

    if (isAfter(new Date(), webinerEndTime)) {
      return this.mtxDialog.alert(
        'Event Ended',
        'Event has already ended. Please check out other events.'
      );
    }
    this.isLiveWebiner.set(!this.isLiveWebiner());
  }
  getMeetingCode = computed(() => {
    const participants = this.webinerDetailsData.data()?.participants || [];
    if (participants.length > 0) {
      switch (participants[0].role) {
        case 'HOST':
          return this.webinerDetailsData.data()!.meetingHostCode;
        case 'CO_HOST':
          return this.webinerDetailsData.data()!.meetingCoHostCode;
        case 'GUEST':
          return this.webinerDetailsData.data()!.meetingGuestCode;
        default:
          return this.webinerDetailsData.data()!.meetingGuestCode;
      }
    } else {
      return this.webinerDetailsData.data()!.meetingHostCode;
    }
  });
  openAddParticipant() {
    const dialogRef = this.dialog.open(AddParticipantWebinerComponent, {
      width: '50vw',
      height: 'auto',
      minHeight: '30vh',
      hasBackdrop: true,
    });

    dialogRef.afterClosed().subscribe((result: DialogRefData) => {
      if (!result.isCancel) {
        const payload = result.data;
        this.mutation.mutate(result.data);
      }
    });
  }
  async completedPayment(event: any) {
    if (event) {
      this.checkoutSession.set(false);
      await this.queryClient.invalidateQueries({
        queryKey: ['market-webiner-details', this.eventId],
      });
    }
  }
  openParticipantDrawer() {
    this.mtxDrawer.open(WebinerParticipantsComponent, {
      width: '60vw',
      hasBackdrop: true,
      data: { eventId: this.eventId },
    });
  }
}
