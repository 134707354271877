<dashboard-content-container title="Events">
  <ng-container content-action>
    <div class="admin-access-menu">
      <div class="button-container">
        <button
          mat-raised-button
          color="primary"
          aria-label="Example icon-button with heart icon"
          [routerLink]="['./../', 'create']">
          <mat-icon>add</mat-icon>
          Add Event
        </button>
        @if (dataView() !== 'CALENDAR') {
          <button mat-raised-button (click)="openDialog()">
            <mat-icon>filter_alt</mat-icon>Filter
          </button>
        }
        @if (dataView() !== 'CALENDAR') {
          <ng-select
            [multiple]="false"
            class="ng-select-custom"
            [ngModel]="this.sortSignal()"
            [clearable]="false"
            (ngModelChange)="onSortChange($event)">
            <ng-option
              *ngFor="let sort of this.sortOption as tags"
              [value]="sort.value"
              >{{ sort.viewValue }}</ng-option
            >
          </ng-select>
          <!-- <mat-form-field appearance="outline">
            <mat-label>Sort By: </mat-label>
            <mat-select
              [value]="this.sortSignal()"
              (selectionChange)="onSortChange($event)"
              defa>
              @for (option of this.sortOption; track option) {
                <mat-option [value]="option.value">{{
                  option.viewValue
                }}</mat-option>
              }
            </mat-select>
          </mat-form-field> -->
        }
        <mat-button-toggle-group
          name="fontStyle"
          aria-label="Font Style"
          [value]="dataView()"
          (change)="changeDataView($event)">
          <mat-button-toggle value="GRID">
            <mat-icon>grid_view</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="LIST">
            <mat-icon>format_list_bulleted</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="CALENDAR">
            <mat-icon>calendar_month</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </ng-container>

  <ng-container content-inner>
    @if (dataView() === 'CALENDAR') {
      <div class="calendar-container">
        <full-calendar
          [options]="calendarOptions"
          [events]="eventCalendarData.data() || []"></full-calendar>
      </div>
    } @else {
      <div
        class="content-container"
        [ngClass]="
          dataView() === 'LIST'
            ? 'content-container-list'
            : 'content-container-grid'
        ">
        @if (webinerQueries.isPending()) {
          <div>loading...</div>
        } @else if (webinerQueries.isError()) {
        } @else {
          @if (webinerQueries.data(); as webiners) {
            @for (webiner of webiners; track webiner) {
              <trlp-webiner-card
                [viewMode]="dataView() === 'LIST' ? 'LIST' : 'GRID'"
                [WebinerDetails]="webiner"></trlp-webiner-card>
            }
          }
        }
      </div>
    }
  </ng-container>
</dashboard-content-container>
